<template>
  <div class="main-container">
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-university" style="margin: 0 10px 0 0;font-size: 18px;"></i>
            <span @click="main()">{{ $t("bank.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name==='bank.create'">
            <span v-show="true">/</span>
            <span>{{ $t("bank.create") }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    main() {
      this.$router.push({
        name: "bank.index"
      }).catch(() => {

      })
    },

  }
}
</script>

<style scoped lang="scss">

</style>
